import useSWR, { useSWRConfig } from 'swr'
import fetcher from '../utils/fetcher'

const USER_ENDPOINT = '/api/v3/user'

/**
 * Fetch the user object
 * @returns {useUserResponse}
 */
export function useUser() {
  const { mutate } = useSWRConfig()

  const { data, error } = useSWR(USER_ENDPOINT, fetcher(), {
    shouldRetryOnError: false, // if we're not logged in, we're not logged in. stop retrying.
  })

  const isAdmin = data?.roles.includes('admin') ?? false // ensure this is a boolean, not true or undefined
  const isReporter = data?.roles.includes('reporter') || isAdmin
  const isTechnician = data?.roles.includes('technician') || isAdmin
  const isPhone = data?.roles.includes('phone') ?? false // Portal Softphone - disabled by default

  return {
    user: {
      ...data,
      isAdmin,
      isReporter,
      isTechnician,
      isPhone,
    },
    isUserLoading: !error && !data,
    isUserError: error,
    reload: async () => {
      await mutate(USER_ENDPOINT)
    },
  }
}

/**
 * @typedef {object} user
 * @property {string} email
 * @property {string} name
 * @property {profile} [profile] Set during the Azure auth flow, but when that occasionally fails, profile is undefined
 * @property {Array<string>} roles
 * @property {Array<string>} scopes
 * @property {settings} settings
 * @property {string} _id
 * @property {boolean} isAdmin
 * @property {boolean} isReporter
 * @property {boolean} isTechnician
 * @property {boolean} isPhone
 *
 * @typedef {object} profile
 * @property {Array<string>} businessPhones
 * @property {string} displayName
 * @property {string} givenName
 * @property {string} id
 * @property {string} jobTitle
 * @property {string} mail
 * @property {string} surname
 * @property {string} userPrincipalName
 *
 * @typedef {object} settings
 * @property {'light' | 'dark' | '' } [theme]
 * @property {'standard' | 'silver' | 'retro' | 'dark' | 'night' | 'aubergine' | '' } [mapsTheme]
 * @property {boolean} [showReimbursementDetails]
 * @property {boolean} [stickyReimbursement]
 * @property {boolean} [stickySaveButton]
 * @property {boolean} [recordingAutoPlay]
 * @property {string} [recordingPlaybackSpeed]
 *
 * @typedef {object} useUserResponse
 * @property {user} user
 * @property {boolean} isUserLoading
 * @property {any} isUserError
 * @property {() => Promise<void>} reload
 */
